import { Button, Card, Col, DatePicker, Input, Layout, message, Modal, Row, Select, Skeleton, Space, Tabs, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import { InstructorHandler } from "./InstructorHandler";
import { CSDDForm } from "./CSDDForm";
import { LessonsList } from "./LessonsList";
import { Activities } from "./Activities";
import { StudentServicesNew } from "./StudentServicesNew";
import { DrivingLessons } from "./DrivingLessons";
import { StudentPayments } from "./StudentPayments";
import { CheckpointsCard } from "./CheckpointsCard";
import moment from "moment";
import { http } from "../../helpers/http";
import { Agreements } from "./Agreements";
import { NotificationsLog } from "./NotificationsLog";
import { PaymentNotificList } from "./PaymentNotificList";
import { AxiosResponse } from 'axios';
import { StudentFreeTheoryLessons } from "./StudentFreeTheoryLessons";
import { CheckIfSpamSent } from "./CheckIfSpamSent";

const { Title, Text } = Typography;
const { TabPane } = Tabs;

export const StudentEdit = withRouter((props: any) => {
    const { id }: { id: string } = useParams();
    const { permissions, fetchStudent, student, setStudent, openedCategory, isFake, studentGroup, commonServices, categoryID } = props;
    const history = useHistory()

    // const [student, setStudent] = useState({} as any);
    const [csdd, setCsdd] = useState({} as any);
    const [comment, setComment] = useState(null as any);
    const [checkpoints, setCheckpoints] = useState([] as any);
    const [studentServices, setStudentServices] = useState([] as any);
    const [group, setGroup] = useState({} as any);
    const [instructors, setInstructor] = useState([] as any);
    const [rTypes, setRTypes] = useState([] as any);
    const [defaultTab, setDefaultTab] = useState(props.location?.state?.tab !== undefined ? props.location?.state?.tab : 1);
    const [loaded, setLoaded] = useState(false);
    const [theoryLessons, setTheoryLessons] = useState([] as any);
    const [allTheoryLessons, setAlltheoryLessons] = useState([] as any);
    const [foreignLessons, setForeignLessons] = useState([] as any);
    // const [allSignedLessons, setAllSignedLessons] = useState([] as any);
    // const [nextTheoryLesson, setNextTheoryLesson] = useState({} as any);
    const [registration, setRegistration] = useState({} as any);
    const [regPrice, setRegprice] = useState(undefined as any);
    const [regDisabled, setRegDisabled] = useState(false);
    const [promo, setPromo] = useState([] as any);
    const [serviceHistory, setServiceHistory] = useState([] as any);
    const [showServicesHistory, setShowServicesHistory] = useState(false);
    const [studentModules, setStudentModules] = useState([] as any);
    const [modules, setModules] = useState([] as any);
    const [passedModules, setPassedModules] = useState([] as any);
    const [activeTab, setActiveTab] = useState(1);
    const [eModuleResetModalVisible, setEmoduleResetModalVisible] = useState({ visible: false, id: 0 });
    const [moduleResetAmount, setModuleResetAmount] = useState(0.00);
    const [cities, setCities] = useState([] as any);

    const fakeGroup = process.env.REACT_APP_NO_GROUP_ID

    const study_group_id = props.study_group_id === Number(fakeGroup)
        ? fakeGroup
        : props.study_group_id

    const fetchServices = async () => {
        const studentServices = await http.get(`/rest/student-study-group-services/get-services/${id}/${study_group_id}`).then((response: any) => {
            return response.data.data.map((item: any) => {
                return {
                    ...item,
                    approved: item.pivot.approved,
                    canceled: item.pivot.canceled,
                    from_web: item.pivot.from_web,
                    paid: item.pivot.paid,
                }
            })
        });

        setStudentServices(studentServices);
    };

    const fetchCities = () => {
        http.get(`/rest/cities`).then((res: AxiosResponse) => {
            setCities(res.data.data)
        })
    }

    const fetchTheoryLessons = async () => {
        await http.get(`/rest/student-study-group-attendance/${id}`, { params: { category: openedCategory } }).then((resp: any) => {
            const { attendance, foreign_lessons } = resp.data
            const attendedLessons = attendance.map((lesson: any) => {
                return {
                    ...lesson.lesson,
                    ...lesson.native
                }
            });

            setTheoryLessons(attendedLessons);
            setForeignLessons(() => {
                const mergedLessons = foreign_lessons.map((lesson: any) => {
                    return {
                        ...lesson.lesson,
                        ...lesson.foreign,
                        paid: lesson.paid
                    }
                })
                return mergedLessons.reverse();
            });
        })
    }

    const fetchDrivingLessonInfo = () => {
        http.get(`/rest/lesson_info/${id}`, { params: { category: openedCategory } })
            .then(result => {
                setInstructor([result.data.data.instructor])
            });
    }

    const fetch = () => {

        fetchServices();

        http.get(`/rest/study-groups/${study_group_id}?skip_students=1`).then((response: any) => {
            setGroup(response.data.data);
        });

        http.get(`/rest/study-groups-reservation-values`).then((response: any) => {
            setRTypes(response.data);
        });

        http.get(`/rest/study-group-csdd-check/${id}/${study_group_id}`).then((response: any) => {
            setCsdd(Array.isArray(response.data.data) ? [] : response.data.data);
        })

        http.get(`rest/study-groups-students-checkpoints/${id}/${study_group_id}`).then((response: any) => {
            setCheckpoints(response.data.data)
        })

        setComment(student.comment);

        // setCheckpoints(checkpoints);

        // setGroup(group);

        // setRTypes(rTypes);

        // setCsdd(Array.isArray(csdd) ? [] : csdd);

    }

    // const fetchStudent = async () => {
    //     const student = await http.get(`/rest/students/${id}`).then((response: any) => {
    //         return response.data.data;
    //     });
    //     const balance = await http.get(`/rest/balance/${id}`).then((response: any) => {
    //         return response.data.data;
    //     });
    //     const reservation_type = await http.get(`/rest/study-groups-get-student-reservation/${id}/${study_group_id}`).then((response: any) => {
    //         return response.data;
    //     });

    //     setStudent((data: any) => {
    //         return {
    //             registration_type: "Online",
    //             ...student,
    //             balance: balance,
    //             reservation_type,
    //         }
    //     });

    //     setModuleResetAmount(Number(student?.category?.elearning_module_exam_fee))
    // };

    const fetchStudentModules = async () => {
        await http.get(`rest/student-modules/${student?.id}`, { params: { category: group?.category?.id } }).then((response: any) => {
            console.log("Student modules: ", response.data.data)
            setStudentModules(response.data.data);
        })
    };

    const fetchModules = async () => {
        await http.get(`rest/learning-modules-list/${group?.category?.id}`).then((response: any) => {
            console.log("Modules list: ", response.data)
            setModules(response.data)
        })
    };

    const fetchPassedModules = async () => {
        await http.get(`rest/passed-modules/${student?.id}`, { params: { category: group?.category?.id } }).then((response: any) => {
            console.log("passed modules: ", response.data.data)
            setPassedModules(response.data.data)
        })
    };

    const checkPromo = async () => {
        await http.post(`rest/check-promo-status/${id}/${study_group_id}`).then((response: any) => {
            console.log(response.data)
            const { data } = response.data;
            if (data === null) {
                setPromo([]);
            } else {
                const promosAndGifts = Object.keys(data).map((key: any) => {
                    return data[key]
                })
                setPromo(promosAndGifts)
            }
        })
    }

    const registrationFetch = async () => {
        const registrationStatus = await http.post(`/rest/student-check-study-group-registration/${id}/${study_group_id}`).then((response: any) => {
            if (response.data.data[0] !== null) {
                return {
                    ...response.data.data[0],
                    paid: response.data.paid
                }
            } else {
                return {}
            }
        })
        setRegistration(registrationStatus)
    }

    useEffect(() => {
        const activeKey = props.history.location.state?.active_key;
        fetchCities();
        if (activeKey) setDefaultTab(activeKey);
        // if (props.study_group_id === process.env.REACT_APP_NO_GROUP_ID) history.push("/")
        if (study_group_id === fakeGroup) {
            setLoaded(true);
            return;
        } else {
            registrationFetch();
            fetch();
            fetchDrivingLessonInfo();
        }
    }, []);

    useEffect(() => {
        if (Object.keys(group).length > 0) {
            setLoaded(true);
        }
    }, [group]);

    useEffect(() => {
        if (student !== undefined) {
            if (student.hasOwnProperty("id") && study_group_id !== fakeGroup) {
                fetchTheoryLessons().then(() => setLoaded(true));
                servicesHistory();
                checkPromo();
            }
        }
    }, [student]);

    useEffect(() => {
        if (activeTab === 5) {
            fetchModules();
            fetchPassedModules();
            fetchStudentModules();
        }
    }, [activeTab])

    const changeGroup = (newService: any) => {
        let copyOfServices = student.additional_services.map((item: any) => {
            return { ...item };
        });
        let serviceToChange = copyOfServices.filter((service: any) => {
            return service.id === newService.id;
        });

        serviceToChange[0] = newService;

        copyOfServices = copyOfServices.map((service: any) => {
            if (service.id === serviceToChange[0].id) {
                service = serviceToChange[0];
            }
            return service;
        })

        setStudent((data: any) => {
            return {
                ...data,
                additional_services: copyOfServices
            }
        })
    }

    const removeRegistration = () => {
        http.post(`/rest/remove-group-registration-payment/${id}/${study_group_id}`).then((response: any) => {
            registrationFetch();
            setRegDisabled(false);
        })
    }

    const removeService = async (service: any) => {
        service.dont_need = 1;
        service.approved = 0;
        service.from_web = 0;
        service.paid = 0;
        service.service = service.pivot.id;
        service.individual_price = 0;
        console.log(service)
        await http.post(`/rest/student-study-group-services/change-service/${service.pivot.id}`, service).then((response) => {
            fetchServices();
        })
    }

    const addService = async (service: any) => {
        console.log(service)
        service.cancelled = 0;
        service.approved = 1;
        service.from_web = 0;
        service.paid = 0;
        service.service = service.id;
        service.student = id;
        // service.individual_price = undefined || 0;
        await http.post(`/rest/student-study-group-services/add-service/${id}`, service).then(async (response) => {
            fetchServices();
        })
    }

    const servicesHistory = async () => {
        http.get(`/rest/get-student-group-services/${id}/${categoryID}`).then((res: AxiosResponse) => {
            console.log(res)
            setServiceHistory(res.data)
        })
        // await http.get(`/rest/student-study-service-history/${id}`).then(async (response: any) => {
        //     console.log(response)
        //     const { services, registrations } = response.data.data;
        //     let sortedServices = [] as any;
        //     let sortedRegistrations = [] as any;
        //     if (Object.keys(services).length > 0) {
        //         sortedServices = Object.keys(services).map((key: any) => {
        //             const servicesList = Object.keys(services[key]).map((serviceKey: any) => {
        //                 if (!!!services[key][serviceKey].pivot.paid) return undefined;
        //                 return services[key][serviceKey];
        //             }).filter((el: any) => el !== undefined);
        //             return servicesList
        //         })
        //         console.log("SERV", sortedServices);
        //     }
        //     if (Object.keys(registrations).length > 0) {
        //         const registrationsList = await Promise.all(Object.keys(registrations).map(async (key: any) => {
        //             const regPaid = registrations[key].registration_paid;
        //             if (regPaid) {
        //                 const registration = await http.post(`rest/student-check-study-group-registration/${id}/${key}`);
        //                 const getReg = registration.data.data;
        //                 return getReg;
        //             }
        //             return undefined;
        //         })) as any;
        //         sortedRegistrations = registrationsList.filter((item: any) => item !== undefined).map((data: any) => {
        //             return {
        //                 ...data[0],
        //                 group: data.group
        //             }
        //         });
        //         console.log("REG", sortedRegistrations)
        //     }
        //     const merged = sortedServices.map((service: any) => {
        //         const groupRegistration = sortedRegistrations.find((reg: any) => {
        //             return reg.study_group_id === service[0]?.study_group_id;
        //         });
        //         if (groupRegistration !== undefined) {
        //             return [
        //                 groupRegistration,
        //                 ...service
        //             ]
        //         }
        //         return service;
        //     });
        //     sortedRegistrations.forEach((reg: any) => {

        //         if (merged.length === 0 || !merged.some((el: any) => el[0]?.study_group_id === reg?.study_group_id)) {
        //             merged.push([reg]);
        //         }

        //     });
        //     console.log(merged)
        //     setServiceHistory(merged);
        // })
    }

    const addActivity = (params: any) => {
        setStudent((data: any) => {
            return {
                ...data,
                activities: data.activities.concat([params])
            }
        })
    }

    const initiateRegistrationPayment = async () => {
        setRegDisabled(true);
        if (regPrice !== undefined || regPrice !== "") {
            await http.post(`/rest/student-create-study-group-registration/${id}/${study_group_id}`, { individual_price: Number(regPrice) })
                .then((response: any) => {
                    console.log(response);
                    registrationFetch();
                }).catch(() => {
                    message.error("Kļūda");
                });
        } else {
            await http.post(`/rest/student-create-study-group-registration/${id}/${study_group_id}`)
                .then((response: any) => {
                    console.log(response);
                    registrationFetch();
                }).catch(() => {
                    message.error("Kļūda");
                });
        }
    }

    const changeServiceData = async (service: any) => {
        if (!service.hasOwnProperty('omniva_terminal_id')) {
            service.omniva_terminal_id = service.pivot.omniva_terminal_id
        }
        service.dont_need = 0;
        service.service = service.pivot.id;
        await http.post(`/rest/student-study-group-services/change-service/${service.pivot.id}`, service).then((response) => {
            fetchServices();
            console.log("SAGLABĀTS")
        })
    }

    const editCsddCheck = (newData: any) => {
        newData.student = id;
        newData.study_group = study_group_id;
        const values = {
            ...newData,
            has_declared_address: newData.has_declared_address === undefined ? 0 : newData.has_declared_address ? 1 : 0,
            has_medical_check: newData.has_medical_check === undefined ? 0 : newData.has_medical_check ? 1 : 0,
            medical_check_date: newData.medical_check_date === undefined || newData.medical_check_date === null ? undefined : moment(newData.medical_check_date).format('YYYY-MM-DD'),
            has_first_aid: newData.has_first_aid === undefined ? 0 : newData.has_first_aid ? 1 : 0,
            first_aid_date: newData.first_aid_date === undefined || newData.first_aid_date === null ? undefined : moment(newData.first_aid_date).format('YYYY-MM-DD'),
            first_aid_nr: newData.first_aid_nr === undefined ? undefined : newData.first_aid_nr,
        }
        Array.isArray(csdd)
            ? http.post('/rest/csdd-check', values)
            : http.patch(`/rest/csdd-check/${csdd.id}`, values).then(response => {
            })
    }

    const reloadCheckpoints = async () => {
        const checkpoints = await http.get(`rest/study-groups-students-checkpoints/${id}/${study_group_id}`).then((response: any) => {
            return response.data.data
        })

        setCheckpoints(checkpoints);
    }

    const rTypeChangeHandler = (value: any) => {
        http.post(`/rest/study-groups-students-change-reservation/${id}/${study_group_id}`, { reservation_type: value }).then((response) => {
        });
    }

    const removeStudent = async () => {
        await http.post(`/rest/study-groups/remove-student/${id}/${study_group_id}`).then((response: any) => {
            window.location.replace(`/groups/edit/${study_group_id}`);
        }).catch(() => {
            message.error("Kļūda!")
        });
    }

    const saveComment = async (del: boolean = false) => {
        del
            ? await http.patch(`/rest/students/${id}`, { comment: null }).then(() => {
                setComment("");
                message.success("Komentārs Dzēsts")
            }).catch(() => {
                message.error("Neizdevās dzēst")
            })
            : await http.patch(`/rest/students/${id}`, { comment: comment }).then(() => {
                message.success("Komentārs saglabāts")
            }).catch(() => {
                message.error("Neizdevās saglabāt")
            })
    }

    const inputHandler = (value: any) => {
        setComment(value.target.value);
    };

    const resetModule = (moduleID: any) => {
        http.post(`rest/create-new-e-learning-module-exam-for-student/${moduleID}`, { student_id: id, amount: moduleResetAmount })
            .then((response: any) => {
                fetchModules();
                fetchPassedModules();
                fetchStudentModules();
                message.success("Modulis atiestatīts!")
            }).catch(() => {
                message.error("Kļūda!")
            })
        setEmoduleResetModalVisible({ visible: false, id: 0 });
    }

    const changeCompletedDate = (moduleId: number) => {
        let newDate = undefined as string | undefined
        Modal.confirm({
            title: "Mainīt datumu",
            content: <div>
                <b style={{ color: "red" }}>Uzmanību! Izmaiņas tiks veiktas arī CSDD sistēmā!</b>
                <p>Jaunais pabeigšanas datums:</p>
                <DatePicker
                    onChange={e => {
                        newDate = e !== undefined ? e?.format("YYYY-MM-DD") : undefined
                    }}
                    style={{ marginBottom: 8 }}
                />
                <p><b>Ja datuma lauks paliks tukšs, datums tiks dzēsts CSDD sistēmā!</b></p>
            </div>,
            onOk: () => {
                if (newDate === undefined) {
                    http.post(`/rest/delete-passed-module/${student.id}/${moduleId}`).then((res: AxiosResponse) => {
                        message.success("Datums veiksmīgi dzēsts!");
                    }).catch(() => {
                        message.error("Kļūda!")
                    })
                } else {
                    http.post(`/rest/change-passed-module/${moduleId}`, { finished_date: newDate }).then((res: AxiosResponse) => {
                        message.success("Datums veiksmīgi nomainīts!");
                        return;
                    }).catch(() => {
                        message.error("Kļūda!")
                    })
                }
            }
        })
    }

    const syncWithCsdd = () => {
        http.post(`/rest/call-csdd-study-elearning/${study_group_id}`).then((res: AxiosResponse) => {
            message.success("Sinhronizācija ar CSDD ir veikta")
        }).catch(() => {
            message.error("Kļūda!")
        })
    }

    return ((!loaded || Object.keys(group).length === 0) && !isFake
        ? <Layout style={{ width: "100%", height: "100vh", backgroundColor: "#FFFFFF", padding: 50 }}>
            <Skeleton paragraph={{ rows: 10 }} active />
        </Layout>
        : <Row>
            <Col span={24}>
                <Card>
                    <Tabs defaultActiveKey={defaultTab} onChange={(e: any) => setActiveTab(Number(e))}>
                        {
                            study_group_id === fakeGroup
                                ? <></>
                                : <>
                                    <TabPane tab="Sākums" key="1">
                                        <Card style={{ width: "100%" }}>
                                            <Row>
                                                <Col span={12}>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Row justify="space-between">
                                                                <Title level={4}>Personīgā informācija</Title>
                                                                <Link to={`/students/edit/${id}`}><Button style={{ marginRight: 30 }}>Labot informāciju</Button></Link>
                                                            </Row>
                                                            <Row gutter={[0, 12]}>
                                                                <Col span={10}>
                                                                    <Text strong >Deklarētā adrese<br /></Text>
                                                                    <Text >{student.declared_address}</Text>
                                                                </Col>
                                                                <Col span={7}>
                                                                    <Text strong >Personas kods<br /></Text>
                                                                    <Text >{student.personal_identity_number}</Text>
                                                                </Col>
                                                                <Col span={7}>
                                                                    <Text strong >Dzimšanas datums<br /></Text>
                                                                    <Text >{student.birthdate}</Text>
                                                                </Col>
                                                                <Col span={8}>
                                                                    <Text strong >Pases numurs<br /></Text>
                                                                    <Text >{student.passport_or_id_number}</Text>
                                                                </Col>
                                                                <Col span={8}>
                                                                    <Text strong >Vecums<br /></Text>
                                                                    <Text >{moment().diff(moment(student.birthdate, "YYYY-MM-DD"), "years")}</Text>
                                                                </Col>
                                                                <Col span={8}>
                                                                    <Text strong >Dzimums<br /></Text>
                                                                    <Text >
                                                                        {student.gender === "male"
                                                                            ? "Vīrietis"
                                                                            : student.gender === "female"
                                                                                ? "Sieviete"
                                                                                : student.gender === "other"
                                                                                    ? "Cits"
                                                                                    : "Nav definēts"}
                                                                    </Text>
                                                                </Col>
                                                                {
                                                                    student.have_orphan
                                                                        ? <Col span={24}>
                                                                            <Card title="Aizgādnis">
                                                                                <Row>
                                                                                    <Col span={8}>
                                                                                        <Text strong >Vārds<br /></Text>
                                                                                        <Text >{student.orphan_name}</Text>
                                                                                    </Col>
                                                                                    <Col span={8}>
                                                                                        <Text strong >Uzvārds<br /></Text>
                                                                                        <Text>{student.orphan_surname}</Text>
                                                                                    </Col>
                                                                                    <Col span={8}>
                                                                                        <Text strong >Personas kods<br /></Text>
                                                                                        <Text >{student.orphan_code}</Text>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Card>
                                                                        </Col>
                                                                        : <></>
                                                                }
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: 30 }}>
                                                        <Col span={24}>
                                                            <Title level={4}>Pieteikuma informācija</Title>
                                                            {
                                                                study_group_id !== fakeGroup
                                                                && <Row align="middle">
                                                                    <div>
                                                                        <b>Grupa:</b>
                                                                        <p><b>{group.csdd_nr}</b></p>
                                                                    </div>
                                                                    <Button
                                                                        type="primary"
                                                                        style={{ marginLeft: 10 }}
                                                                        onClick={() => history.push(`/groups/edit/${study_group_id}`)}
                                                                    >
                                                                        Atvērt
                                                                    </Button>
                                                                </Row>
                                                            }
                                                            <Row align="top">
                                                                <Col span={12}>
                                                                    <Text strong >Rezervācijas veids:<br /></Text>
                                                                    <Select
                                                                        defaultValue={studentGroup.reservation_type}
                                                                        style={{ minWidth: 100, marginTop: 8 }}
                                                                        onChange={rTypeChangeHandler}
                                                                        disabled={registration?.paid === 0 || Object.keys(registration).length === 0}
                                                                    >
                                                                        {
                                                                            rTypes.map((type: any) => {
                                                                                if (type === "interested" && registration?.paid) {
                                                                                    return;
                                                                                }
                                                                                if (type === "foreign") return;
                                                                                return <Select.Option key={type} value={type}>{type}</Select.Option>
                                                                            }).filter((item: any) => {
                                                                                return item !== undefined;
                                                                            })
                                                                        }
                                                                    </Select><br />
                                                                    <div style={{ marginTop: 8 }}>
                                                                        <b>Reģistrācija: </b>
                                                                        {
                                                                            Object.keys(registration).length > 0
                                                                                ? registration.paid
                                                                                    ? <Space>
                                                                                        <b>{Number(registration.amount) * -1} EUR</b>
                                                                                        <Tag color="success">Apmaksāts</Tag>
                                                                                        <Button onClick={removeRegistration}>Anulēt</Button>
                                                                                    </Space>
                                                                                    : <Space>
                                                                                        <b>{Number(registration.amount) * -1} EUR</b>
                                                                                        <Tag color="red">Nav Apmaksāts</Tag>
                                                                                        <Button onClick={removeRegistration}>Noraidīt</Button>
                                                                                    </Space>
                                                                                : <div style={{ marginTop: 8 }}>
                                                                                    <b>Parastā cena: {group.registration_fee} EUR</b><br />
                                                                                    <Input
                                                                                        type="number"
                                                                                        min={0}
                                                                                        step={1}
                                                                                        style={{ width: 70, marginRight: 8 }}
                                                                                        onChange={(e: any) => setRegprice(e.target.value)}
                                                                                    />
                                                                                    <b>EUR</b>
                                                                                    <Button
                                                                                        style={{ marginLeft: 10 }}
                                                                                        type="primary"
                                                                                        onClick={initiateRegistrationPayment}
                                                                                        disabled={regDisabled}
                                                                                    >
                                                                                        Pieteikt
                                                                                    </Button>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                                <Col span={12} style={{ paddingTop: 2 }}>
                                                                    <Text strong >Pieteikuma veids<br /></Text>
                                                                    <Text>{student.web_app_ref !== null ? "WEB" : "Sistēma"}</Text>
                                                                </Col>
                                                                <Col span={24}>
                                                                    {
                                                                        student.web_referer !== null || student.web_city_name !== null || student.web_lang !== null
                                                                            ? <>
                                                                                {
                                                                                    student.web_referer !== null
                                                                                        ? <div style={{ marginTop: 10 }}>
                                                                                            <Text strong >Pieteikums no:</Text><br />
                                                                                            <a>{student.web_referer}</a>
                                                                                        </div>
                                                                                        : <></>
                                                                                }
                                                                                {
                                                                                    student.web_city_name !== null
                                                                                        ? <div style={{ marginTop: 10 }}>
                                                                                            <Text strong >Pieteikuma pilsēta:</Text><br />
                                                                                            <b style={{ display: "block" }}>{student.web_city_name}</b>
                                                                                        </div>
                                                                                        : <></>
                                                                                }
                                                                                {
                                                                                    student.web_lang !== null
                                                                                        ? <div style={{ marginTop: 10 }}>
                                                                                            <Text strong >Pieteikuma valoda:</Text><br />
                                                                                            <b style={{ display: "block" }}>{student.web_lang}</b>
                                                                                        </div>
                                                                                        : <></>
                                                                                }
                                                                            </>
                                                                            : <></>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: 30 }}>
                                                        <Col span={24}>
                                                            <Title level={4}>Apmācības informācija</Title>
                                                            <Row>
                                                                <Col span={12}>
                                                                    <Text strong >Pirmās lekcijas datums:<br /></Text>
                                                                    <Text>{
                                                                        group?.theory_lessons?.length > 0
                                                                            ? group?.theory_lessons[0]?.lesson_date
                                                                                ? moment(group?.theory_lessons[0]?.lesson_date).format("DD.MM.YYYY")
                                                                                : ""
                                                                            : ""
                                                                    } {
                                                                            group.cities.length > 0
                                                                                ? group.cities[0]?.value
                                                                                : ""
                                                                        }
                                                                    </Text>
                                                                </Col>
                                                                <Col span={10}>
                                                                    <Text strong >Apmācība pabeigta:<br /></Text>
                                                                    <Text>
                                                                        {
                                                                            allTheoryLessons.length === theoryLessons.filter((el: any) => {
                                                                                return el.attained;
                                                                            }).length
                                                                                ? theoryLessons[theoryLessons.length - 1]?.lesson_date
                                                                                : <b>Apmācība nav pabeigta</b>
                                                                        }
                                                                    </Text>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col span={24}>
                                                            <div style={{ padding: "15px 20px 0 0" }}>
                                                                <Text strong>Komentārs:</Text>
                                                                <Input.TextArea
                                                                    value={comment}
                                                                    onChange={inputHandler}
                                                                    autoSize={{ maxRows: 5, minRows: 5 }}
                                                                    style={{ marginTop: 10, marginBottom: 10 }}
                                                                />
                                                                <Space>
                                                                    <Button type="primary" onClick={() => saveComment()}>Saglabāt komentāru</Button>
                                                                    <Button danger onClick={() => saveComment(true)}>Dzēst Komentāru</Button>
                                                                </Space>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={12}>
                                                    <CSDDForm csdd={csdd} edit={editCsddCheck} />
                                                    <CheckpointsCard studentGroup={studentGroup} checkpoints={group.checkpoints} reload={reloadCheckpoints} studentCp={checkpoints} />
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: 30 }}>
                                                <Col span={24} style={{ marginBottom: 30 }}>
                                                    <Title level={4}>Atlaides</Title>
                                                    {
                                                        promo.length > 0
                                                            ? promo?.map((item: any) => {
                                                                const sum = item.promo?.promo_logic === "sum";
                                                                return <Card style={{ backgroundColor: "#f5fced" }}>
                                                                    <Row gutter={16}>
                                                                        <Col span={8}>
                                                                            {
                                                                                sum
                                                                                    ? <b>Promo: {Number(item.promo?.amount).toFixed(2) + " EUR"}</b>
                                                                                    : <b>Promo: {Number(item.promo?.amount).toFixed(0) + " %"}</b>
                                                                            }
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <b>Kods: {item.promo?.code}</b>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            {
                                                                                item.registration?.length === 0 && item.services?.length === 0
                                                                                    ? <b>Piemērots kopsummai</b>
                                                                                    : <></>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </Card>
                                                            })
                                                            : <b>Studentam nav piemērotas atlaides</b>
                                                    }
                                                </Col>
                                                <Col span={24} style={{ marginBottom: 30 }}>
                                                    <Title level={4}>Pakalpojumu vēsture</Title>
                                                    {
                                                        serviceHistory.length === 0
                                                            ? <b>Studentam nav pakalpojumu vēstures</b>
                                                            : <Button
                                                                onClick={() => setShowServicesHistory(!showServicesHistory)}
                                                            >
                                                                {showServicesHistory ? "Paslēpt vēsturi" : "Parādīt"}
                                                            </Button>
                                                    }
                                                    {
                                                        serviceHistory.length > 0
                                                        && <Row gutter={[16, 16]} style={{ marginTop: 15 }}>
                                                            {
                                                                showServicesHistory
                                                                    ? serviceHistory?.map((group: any, index: number) => {
                                                                        return <Col key={index} span={12}>
                                                                            <Card title={"Grupa: " + group.csdd_nr}>
                                                                                {
                                                                                    group.registration
                                                                                    && <Space>
                                                                                        <b>Reģistrācija</b>
                                                                                        <b>{group.registration} EUR</b>
                                                                                    </Space>
                                                                                }
                                                                                {
                                                                                    group.services.map((service: any, ind: number) => {
                                                                                        return <Card key={ind}>
                                                                                            <Space>
                                                                                                <b>{service.value_lv}</b>
                                                                                                <b>{service.amount} EUR</b>
                                                                                            </Space>
                                                                                        </Card>
                                                                                        return
                                                                                    })
                                                                                }
                                                                            </Card>
                                                                        </Col>
                                                                    })
                                                                    : <></>
                                                            }
                                                        </Row>
                                                    }
                                                    {
                                                        !showServicesHistory
                                                            ? <></>
                                                            : <Button onClick={() => setShowServicesHistory(!showServicesHistory)}>Paslēpt vēsturi</Button>
                                                    }
                                                </Col>
                                                <Col span={24}>
                                                    <Title level={4}>Pakalpojumi</Title>
                                                </Col>
                                            </Row>
                                            <Col>
                                                <StudentServicesNew
                                                    services={studentServices}
                                                    allServices={group.services}
                                                    changeGroup={changeGroup}
                                                    remove={(service: any) => removeService(service)}
                                                    add={addService}
                                                    edit={changeServiceData}
                                                    extraServices={[...student?.extra_medical, ...student?.extra_pmp, ...student?.extra_omniva]}
                                                    refreshStudent={() => fetchStudent()}
                                                    commonServices={commonServices}
                                                />
                                            </Col>
                                            <InstructorHandler instructors={instructors} />
                                        </Card>
                                    </TabPane>
                                    <TabPane tab="Līgumi" key="2">
                                        <Agreements
                                            student={student}
                                            registration={registration}
                                            group={group}
                                        />
                                    </TabPane>
                                </>
                        }

                        <TabPane tab="CRM" key="3">
                            <Activities
                                study_group_id={study_group_id}
                                studentActivities={student.activities}
                                addActivities={(values: any) => addActivity(values)}
                                fakeID={study_group_id === fakeGroup ? fakeGroup : undefined}
                                permissions={permissions}
                                categoryID={categoryID}
                                student={student}
                                refreshStudent={fetchStudent}
                                cities={cities}
                                studentGroup={studentGroup}
                            />
                        </TabPane>
                        <TabPane tab="Braukšana" key="4">
                            <DrivingLessons openedCategory={openedCategory} />
                        </TabPane>
                        {
                            study_group_id !== fakeGroup
                                ? <TabPane tab="Teorija" key="5">
                                    <StudentFreeTheoryLessons student={student} refresh={fetchStudent} />
                                    {
                                        (group?.course_type?.id === 3 && !!group?.real_elearning)
                                        && <Card
                                            title={<span>
                                                <b>E-apmācības moduļi</b>
                                                {
                                                    passedModules.length > 0
                                                    && <Button
                                                        style={{ marginLeft: 20 }}
                                                        onClick={syncWithCsdd}
                                                    >
                                                        Sinhronizēt ar CSDD
                                                    </Button>
                                                }
                                            </span>}
                                            style={{ marginBottom: 10 }}
                                        >
                                            {
                                                modules?.map((module: any, index: any) => {
                                                    const passed = passedModules.find((el: any) => {
                                                        return el.e_module_id === module.id
                                                    });
                                                    const studentModule = studentModules.find((el: any) => {
                                                        return el.id === module.id
                                                    });
                                                    const failed = studentModule?.failed_exam;
                                                    const started = studentModule?.started > 0;
                                                    const havePaymentRequest = !!studentModule?.have_extra_payment
                                                    console.log(failed)
                                                    const renewModuleForFree = () => {
                                                        Modal.confirm({
                                                            title: "Uzmanību!",
                                                            content: "Apstiprinot, studentam tiks atjaunota iespēja kārtot eksāmenu, neatkārtojot nodaļas!",
                                                            onOk: () => {
                                                                http.post(`rest/reset-student-module-exam/${id}/${module.id}`).then((res: AxiosResponse) => {
                                                                    fetchModules();
                                                                    fetchPassedModules();
                                                                    fetchStudentModules();
                                                                    message.success("Modulis atjaunots!");
                                                                }).catch(() => {
                                                                    message.error("Kļūda!")
                                                                })
                                                            }
                                                        })
                                                    }
                                                    const renewChapters = () => {
                                                        Modal.confirm({
                                                            title: "Uzmanību!",
                                                            content: "Apstiprinot, studentam tiks atjaunota iespēja kārtot eksāmenu, neatkārtojot nodaļas!",
                                                            onOk: () => {
                                                                http.post(`rest/give-module-chapters/${id}/${module.id}`).then((res: AxiosResponse) => {
                                                                    message.success("Nodaļas atjaunotas!");
                                                                    // fetchModules();
                                                                    // fetchPassedModules();
                                                                    // fetchStudentModules();
                                                                })
                                                            }
                                                        })
                                                    }

                                                    return <Card
                                                        key={index}
                                                        style={{
                                                            marginBottom: 8,
                                                            backgroundColor: passed !== undefined && passed
                                                                ? "#acffac"
                                                                : failed
                                                                    ? !havePaymentRequest
                                                                        ? "rgb(255 177 172)"
                                                                        : "#ffffb4"
                                                                    : ""
                                                        }}
                                                    >
                                                        <Row gutter={16}>
                                                            <Col span={4}>
                                                                Nr: {index + 1}&nbsp;
                                                                <b>{module?.title_lv}</b>
                                                            </Col>
                                                            <Col span={12}>
                                                                <b>{module?.description_lv}</b>
                                                            </Col>
                                                            <Col span={8}>
                                                                {
                                                                    passed !== undefined && passed
                                                                        ? <div>
                                                                            <p>Pabeigts</p>
                                                                            <b>{passed.finished_date}</b>
                                                                            <Button
                                                                                type="link"
                                                                                onClick={() => changeCompletedDate(passed.id)}
                                                                            >
                                                                                Mainīt
                                                                            </Button>
                                                                        </div>
                                                                        : failed
                                                                            ? !havePaymentRequest
                                                                                ? "Nav nokārtots"
                                                                                : "Nosūtīts apmaksas pieprasījums"
                                                                            : started
                                                                                ? "Sākts"
                                                                                : "Nav sākts"
                                                                }
                                                                {
                                                                    failed
                                                                        ? havePaymentRequest
                                                                            ? <></>
                                                                            : <div>
                                                                                <Button
                                                                                    style={{ marginTop: 10 }}
                                                                                    onClick={() => {
                                                                                        setEmoduleResetModalVisible({ visible: true, id: module.id })
                                                                                    }}
                                                                                >
                                                                                    Atjaunot piekļuvi par maksu
                                                                                </Button>
                                                                                {
                                                                                    (!!permissions.ruler_of_universe || permissions.perms.some((el: any) => el === "chapter_reset_operator"))
                                                                                    && <Button
                                                                                        style={{ marginTop: 10 }}
                                                                                        onClick={() => renewModuleForFree()}
                                                                                    >
                                                                                        Atjaunot piekļuvi bez maksas
                                                                                    </Button>
                                                                                }
                                                                            </div>
                                                                        : <></>
                                                                }
                                                                {
                                                                    (started && !passed && !failed && (!!permissions.ruler_of_universe || permissions.perms.some((el: any) => el === "chapter_reset_operator")))
                                                                    && <Button
                                                                        style={{ marginLeft: 10 }}
                                                                        onClick={renewChapters}
                                                                        type="ghost"
                                                                        danger
                                                                    >
                                                                        Uzstādīt visas moduļa nodarbības noklausītas
                                                                    </Button>
                                                                }
                                                                <Modal
                                                                    visible={eModuleResetModalVisible.visible}
                                                                    onOk={() => resetModule(eModuleResetModalVisible.id)}
                                                                    onCancel={() => setEmoduleResetModalVisible({ visible: false, id: 0 })}
                                                                    forceRender
                                                                >
                                                                    Atkārtota moduļa piekļuves maksa:
                                                                    <Input
                                                                        value={moduleResetAmount}
                                                                        style={{ width: 100, marginLeft: 10 }}
                                                                        onChange={(e: any) => setModuleResetAmount(e.target.value)}
                                                                        type="number"
                                                                        min={0}
                                                                        step={0.01}
                                                                    />
                                                                    &nbsp;&euro;
                                                                </Modal>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                })
                                            }
                                        </Card>
                                    }
                                    <LessonsList
                                        // allLessons={allTheoryLessons}
                                        studentLessons={theoryLessons}
                                        foreignLessons={foreignLessons}
                                        student={student}
                                        group={group}
                                        refetch={fetchTheoryLessons}
                                        haveElearning={group?.course_type?.id === 3}
                                    />
                                </TabPane>
                                : <></>
                        }
                        <TabPane tab="Maksājumi" key="6">
                            <StudentPayments
                                self={student}
                                groupID={study_group_id}
                            />
                        </TabPane>
                        <TabPane tab="Paziņojumi" key="7">
                            <CheckIfSpamSent
                                isSent={student.notification_spam_recieved}
                                refreshStudent={fetchStudent}
                                studentId={student.id}
                                groupId={study_group_id}
                            />
                            <NotificationsLog
                                studentId={id}
                            />
                        </TabPane>
                        <TabPane tab="Maksājumu Paziņojumi" key="8">
                            <PaymentNotificList
                                id={id}
                            />
                        </TabPane>
                    </Tabs>
                    <Row style={{ marginTop: 30 }} justify="space-between">
                        {/* <Button onClick={saveAll} type="primary">Saglabāt</Button> */}
                        {
                            study_group_id === fakeGroup
                                ? <></>
                                : <Space>
                                    <Button
                                        onClick={() => history.push(`/groups/edit/edit-student/${id}/${study_group_id}/change-group`, { group: group })}
                                    >
                                        Mainīt grupu
                                    </Button>
                                    <Button onClick={removeStudent} type="primary" danger>Dzēst studentu</Button>
                                </Space>
                        }
                    </Row>
                </Card>
            </Col>
        </Row>
    )
})