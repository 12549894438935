import { Layout, Space, Button, Table } from "antd";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { getColumnSearchProps } from "../helpers/getColumnSearchProps";
import { http } from "../helpers/http";

const { Header, Content } = Layout;

function Activity(props: { status: "new" | "paid" }) {
    const isActive = props.status === "paid";
    const text = isActive ? "Aktīvs" : "Neaktīvs";
    const icon = <span style={{
        display: "inline-block",
        width: 6,
        height: 6,
        borderRadius: "50%",
        backgroundColor: isActive ? "#25c253" : "#c22525",
        marginBottom: 2
    }}></span>;
    return <span style={{ fontWeight: 600 }}>{icon} {text}</span>
}

export type StudentsPageProps = RouteComponentProps & {
    windowHeight: number,
    prevLocation: any,
    mutateHistory: any
}

export type StudentsPageState = {
    data: any,
    pagination: any,
    loading: any,
    classifiers: any,
    tableState: any,
    schools: 1 | 0
}

export class BaseStudentsPage extends React.Component<StudentsPageProps, StudentsPageState> {
    constructor(props: StudentsPageProps) {
        super(props);
        this.state = {
            data: [],
            pagination: {
                current: 1,
                pageSize: 15,
            },
            loading: true,
            classifiers: {},
            tableState: {} as any,
            schools: 0
        };
    }

    componentDidMount() {
        const { prevLocation } = this.props;
        const resetFilter = prevLocation !== undefined && !prevLocation.includes("student");
        // console.log("reset:", resetFilter, prevLocation)
        if (resetFilter) {
            sessionStorage.removeItem("studentsTableState");
        }
        // console.log(this.props.prevLocation)
        this.loadClassifiers().then(() => {
            if (sessionStorage.getItem("studentsTableState")) {
                const studentsTableState = JSON.parse(sessionStorage.studentsTableState)
                this.setState({ tableState: studentsTableState })
                const { pagination, filters, sorter } = studentsTableState;
                this.handleTableChange(pagination, filters, sorter)
            } else {
                this.fetch();
            }
        })
    }

    componentDidUpdate(prevProps: StudentsPageProps, prevState: StudentsPageState) {
        if (prevState.schools !== this.state.schools) {
            this.fetch({ pagination: { current: 1 }, schools: this.state.schools })
        }
    }

    componentWillUnmount() {
        this.props.mutateHistory(window.location.href)
    }

    async loadClassifiers() {
        const languages = await http.get("/rest/languages");
        const cities = await http.get("/rest/cities");
        const categories = await http.get("/rest/categories");

        this.setState({
            classifiers: {
                languages: languages.data.data,
                cities: cities.data.data,
                categories: categories.data.data
            }
        });
    }

    handleTableChange = (pagination: any, filters: any, sorter: any) => {
        const tableState = {
            pagination,
            filters,
            sorter: sorter.hasOwnProperty("field")
                ? {
                    columnKey: sorter.columnKey,
                    field: sorter.field,
                    order: sorter.order
                }
                : {}
        }
        console.log(tableState)
        this.setState({ tableState: tableState })
        sessionStorage.setItem("studentsTableState", JSON.stringify(tableState));
        this.fetch({
            sortField: sorter.order ? sorter.field : null,
            sortOrder: sorter.order || null,
            pagination,
            filters,
        });
    };

    async fetch(params: any = { pagination: { current: 1 } }) {
        // const timeout = setTimeout(() => { this.setState({ loading: true }); }, 150);
        this.setState({ loading: true })
        await http.get("/rest/students", { params: this.getParams(params) }).then(result => {
            const { data, meta } = result.data;
            // clearTimeout(timeout);
            this.setState({
                data,
                pagination: {
                    current: meta.current_page,
                    pageSize: meta.per_page,
                    total: meta.total
                },
                loading: false
            });
        });
    }

    getParams(params: any) {
        return {
            page: params.pagination.current,
            sortField: params.sortField,
            sortOrder: params.sortOrder,
            filters: params.filters,
            schools: this.state.schools,
        }
    }

    render() {
        const { tableState } = this.state;
        const columns = [
            {
                title: 'Statuss',
                width: 30,
                dataIndex: 'registration_status',
                key: 'registration_status',
                sorter: true,
                sortOrder: tableState?.sorter?.columnKey === 'registration_status' && tableState?.sorter?.order,
                fixed: 'left',
                filters: [
                    { text: 'Neaktīvs', value: 'new' },
                    { text: 'Aktīvs', value: 'paid' },
                ],
                filteredValue: tableState?.filters?.registration_status || null,
                render: (status: any, all: any) => {
                    return <Activity status={status} />
                }
            },
            {
                title: 'Vārds',
                width: 30,
                sorter: true,
                sortOrder: tableState?.sorter?.columnKey === 'first_name' && tableState?.sorter?.order,
                dataIndex: 'first_name',
                key: 'first_name',
                fixed: 'left',
                ...getColumnSearchProps(),
                filteredValue: tableState?.filters?.first_name || null,
            },
            {
                title: 'Uzvārds',
                width: 30,
                sorter: true,
                sortOrder: tableState?.sorter?.columnKey === 'last_name' && tableState?.sorter?.order,
                dataIndex: 'last_name',
                key: 'last_name',
                fixed: 'left',
                ...getColumnSearchProps(),
                filteredValue: tableState?.filters?.last_name || null,
            },
            {
                title: 'Personas kods',
                width: 30,
                dataIndex: 'personal_identity_number',
                key: 'personal_identity_number',
                ...getColumnSearchProps(),
                filteredValue: tableState?.filters?.personal_identity_number || null,
            },
            {
                title: 'Kategorija',
                dataIndex: 'category',
                key: 'category',
                width: 25,
                sorter: true,
                sortOrder: tableState?.sorter?.columnKey === 'category' && tableState?.sorter?.order,
                align: "center",
                filters: this.state.classifiers.categories != null ? this.state.classifiers.categories.map((cat: any) => ({
                    text: cat.value, value: cat.id
                })) : [],
                filteredValue: tableState?.filters?.category || null,
                // filters: [
                //   { text: 'B', value: 1 },
                //   { text: 'A', value: 2 },
                //   { text: 'C', value: 3 },
                // ],
                render: (category: any, record: any) => record.category_letters
            },
            {
                title: 'Pilsēta',
                dataIndex: 'city',
                key: 'city',
                sorter: true,
                sortOrder: tableState?.sorter?.columnKey === 'city' && tableState?.sorter?.order,
                width: 25,
                filters: this.state.classifiers.cities != null ? this.state.classifiers.cities.map((c: any) => ({
                    text: c.value, value: c.id
                })) : [],
                filteredValue: tableState?.filters?.city || null,
                // filters: [
                //   { text: 'Rīga', value: 1 },
                //   { text: 'Valmiera', value: 2 },
                // ],
                render: (city: any) => city?.value
            },
            {
                title: 'Valoda',
                dataIndex: 'language',
                key: 'language',
                sorter: true,
                sortOrder: tableState?.sorter?.columnKey === 'language' && tableState?.sorter?.order,
                width: 25,
                filters: this.state.classifiers.languages != null ? this.state.classifiers.languages.map((c: any) => ({
                    text: c.value, value: c.id
                })) : [],
                filteredValue: tableState?.filters?.language || null,
                // filters: [
                //   { text: 'Latviešu', value: 1 },
                //   { text: 'Krievu', value: 2 },
                // ],
                render: (language: any) => language?.value
            },
            {
                title: 'E-pasts',
                width: 45,
                sorter: true,
                sortOrder: tableState?.sorter?.columnKey === 'email' && tableState?.sorter?.order,
                dataIndex: 'email',
                key: 'email',
                ellipsis: true,
                ...getColumnSearchProps(),
                filteredValue: tableState?.filters?.email || null,
            },
            {
                title: 'Tālrunis',
                width: 30,
                sorter: true,
                sortOrder: tableState?.sorter?.columnKey === 'phone_number' && tableState?.sorter?.order,
                dataIndex: 'phone_number',
                key: 'phone_number',
                ellipsis: true,
                ...getColumnSearchProps(),
                filteredValue: tableState?.filters?.phone_number || null,
            },
            // {
            //     title: 'Braukšana',
            //     width: 25,
            //     // sorter: true,
            //     dataIndex: 'brauksana',
            //     key: 'brauksana',
            //     ellipsis: true,
            // },
            // {
            //     title: 'Depozīts',
            //     width: 25,
            //     // sorter: true,
            //     dataIndex: 'deposit',
            //     key: 'deposit',
            //     ellipsis: true,
            // },
            {
                title: '',
                dataIndex: '',
                key: '',
                width: 25,
                render: () => <Button type="link">Ielogoties</Button>,
                onCell: (record: any, rowIndex: any) => {
                    return {
                        onClick: (event: any) => {
                            event.stopPropagation();
                            const token = record.api_token;
                            const link = `${process.env.REACT_APP_STUDENT_URL}?bt=${token}`;
                            window.open(link);
                        }, // click row
                        onDoubleClick: (event: any) => { }, // double click row
                        onContextMenu: (event: any) => { }, // right button click row
                        onMouseEnter: (event: any) => { }, // mouse enter row
                        onMouseLeave: (event: any) => { }, // mouse leave row
                    };
                }
            }
        ] as any;

        const { data, pagination, loading } = this.state;
        return (
            <Layout>
                <Header style={{ padding: "0 16px", background: "rgb(240, 242, 245)", borderBottom: "2px solid #e7e7e7", flexWrap: 'nowrap' }}>
                    <Space>
                        <Link to='/students/new'><Button type="primary">Pievienot studentu</Button></Link>
                        <Link to='/students/from-web'><Button >Studenti no WEB</Button></Link>
                        <Link to='/students/from-web-to-instructors'><Button >Pieteikumi pie instruktoriem no WEB</Button></Link>
                        <Link to='/students/students-on-hold'><Button>Studenti, kuri kavē braukšanu</Button></Link>
                        <Link to='/students/students-credits'><Button>Kredīti</Button></Link>
                        <Link to='/students/students-with-remaining-balance'><Button>Studentu bilance</Button></Link>
                        {/* <Button
                            onClick={() => this.setState({ schools: !!this.state.schools ? 0 : 1 })}
                        >
                            {
                                !!this.state.schools
                                    ? "Skolas "
                                    : "Einšteins "
                            }
                            studenti
                        </Button> */}
                    </Space>
                </Header>
                <Content>
                    <Table
                        rowKey={record => record.id}
                        columns={columns as any}
                        rowClassName="clickable-row"
                        pagination={{
                            showSizeChanger: false,
                            size: 'small',
                            showTotal: (total) => <div>Kopā {total} studenti</div>,
                            position: ['bottomCenter'],
                            pageSizeOptions: ['15'],
                            defaultPageSize: 15,
                            ...pagination
                        }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {
                                    this.props.history.push(`/students/edit/${record.id}`);
                                }, // click row
                                onDoubleClick: event => { }, // double click row
                                onContextMenu: event => { }, // right button click row
                                onMouseEnter: event => { }, // mouse enter row
                                onMouseLeave: event => { }, // mouse leave row
                            };
                        }}
                        dataSource={data}
                        loading={loading}
                        scroll={{ x: 1600, y: this.props.windowHeight - 185 }}
                        onChange={this.handleTableChange}
                    />
                </Content>
            </Layout>
        );
    }
}

export const StudentsPage = withRouter(BaseStudentsPage);