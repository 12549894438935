import React from 'react';
import './App.scss';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    matchPath
} from "react-router-dom";

import { Button, Card, Form, Input, Layout, Menu, message } from 'antd';
import { StudentsPage } from './pages/StudentsPage';
import { InstructorsPage } from './pages/InstructorsPage';
import { NewStudentPage } from './pages/NewStudentPage';
import { NewInstructorPage } from './pages/NewInstructorPage';
import { EditInstructorPage } from './pages/EditInstructorPage';
import { OptionsPage } from './pages/OptionsPage';
import { http } from './helpers/http';
import { PaymentsPage } from './pages/PaymentsPage/index';
import { StudentPaymentsPage } from './pages/StudentPaymentsPage';
import { LecturerPage } from './pages/Lecturers/LecturerPage';
import { EditLecturerPage } from './pages/Lecturers/EditLecturerPage';
import { NewLecturerPage } from './pages/Lecturers/NewLecturerPage';
import { GroupsPage } from './pages/Groups/Groups';
import { GroupInfoPage } from './pages/Groups/GroupInfoPage';
import { EditGroupInfo } from './pages/Groups/EditGroupInfo';
import { EditLessonsSchedule } from './pages/Groups/EditLessonsSchedule';
import { NewGroup } from './pages/Groups/NewGroup';
import { StudentEdit } from './pages/Groups/StudentEdit';
import { AddStudent } from './pages/Groups/AddStudent';
import { GroupTemplatesPage } from './pages/Groups/GroupTemplatesPage';
import { GroupTemplate } from './pages/Groups/GroupTemplate';
import { Reminders } from './pages/CRM/Reminders';
import { BooksShipping } from './pages/CRM/BooksShipping';
import { MissedLessons } from './pages/CRM/MissedLessons';
import { MedicalGroups } from './pages/PMP/MedicalGroups';
import { NewMedicalGroup } from './pages/PMP/NewMedicalGroup';
import { EditMedicalGroup } from './pages/PMP/EditMedicalGroup';
import { InvoicesList } from './pages/Invoices/InvoicesList';
import { InstructorInvoice } from './pages/Invoices/InstructorInvoice';
import { InstructorBalance } from './pages/Invoices/InstructorBalance';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import lv from "./locale/lv.json";
import moment from 'moment';
import { InvoiceDetails } from './pages/Invoices/InvoiceDetails';
import { FeedbackPage } from './pages/Feedback/FeedbackPage';
import { HolidaysPage } from './pages/Holidays/HolidaysPage';
import { FreeSlotsPage } from './pages/FreeTimeSlots/FreeSlotsPage';
import { StudentsFromWeb } from './pages/StudentsFromWeb';
import { StudentsFromWebToInstructors } from './pages/StudentsFromWebToInstructors';
import { UsersMain } from './pages/Users/UsersMain';
import { ProtectedRoute } from './pages/ProtectedRoute';
import { StudentsOnHold } from './pages/StudentsOnHold/StudentsOnHold';
import { ElearningMain } from './pages/Elearning/ElearningMain';
import { CategoryView } from './pages/Elearning/CategoryView';
import { ModuleView } from './pages/Elearning/ModuleView';
import { ExamQuestionsView } from './pages/Elearning/ExamQuestionsView';
import { ChapterView } from './pages/Elearning/ChapterView';
import buildJson from "./build.json";
import { TranslationsView } from './pages/Translations/TranslationsView';
import { NotificationsView } from './pages/Notifications/NotificationsView';
import { MassGroupsEdit } from './pages/Groups/MassGroupsEdit';
import { StudentsCredits } from './pages/StudentsCredits';
import { TheoryExams } from './pages/Exams/TheoryExams';
import { StudentsRemainingBalance } from './pages/StudentsRemainingBalance';
import { StudentSpamFilterLayout } from './pages/StudentSpamFilter/StudentSpamFilterLayout';
import { EvaluatingOptionsLayout } from './pages/Evaluating/EvaluatingOptionsLayout';
import { SchoolsPage } from './pages/Schools/SchoolsPage';
import { WhatsappNotifications } from './pages/Notifications/WhatsappNotifications';
import { AxiosResponse } from 'axios';
import { StudentGroupOpening } from './pages/Groups/StudentGroupOpening';
import { EditStudent } from './pages/EditStudentPage/EditStudent';
import { ResultsPage } from './pages/Elearning/ResultsPage';
import { ExamResults } from './pages/Elearning/ExamResults';
import { TemplatesLayout } from './pages/Notifications/TemplatesLayout';
import { ExchangeCarsLayout } from './pages/ExcahngeCars/ExchangeCarsLayout';
import { GrouptStatisticsLayout } from './pages/GroupStatistics/GrouptStatisticsLayout';
import { LecturersStats } from './pages/Lecturers/LecturersStats';
import { GroupsCalendarLayout } from './pages/GroupStatistics/GroupsCalendarLayout';
import { OnlineStatsLayout } from './pages/OnlineStats/OnlineStatsLayout';
import { CityDetails } from './pages/OnlineStats/CityDetails';
import { ModuleDetails } from './pages/OnlineStats/ModuleDetails';
import { StudentQuestionsView } from './pages/Elearning/StudentQuestionsView';
import { RemindersAccess } from './pages/CRM/RemindersAccess';
import { DelegatedReminders } from './pages/CRM/DelegatedReminders';
import { DrivingSync } from './pages/Driving/DrivingSync';
import { ApplicationsView } from './pages/CRM/ApplicationsView';
import { SyncErrorsPage } from './pages/Elearning/SyncErrorsPage';
import ExceptionsForInstructorsSearch from './pages/Options/ExceptionsForInstructorsSearch';
import DrivenStats from './pages/InstructorViews/DrivenStats';
import { PMPOverview } from './pages/PMP/PMPOverview';

moment.updateLocale("lv", {
    weekdaysMin: ["Sv", "Pr", "Ot", "Tr", "Ce", "Pk", "Se"],
    week: {
        dow: 1,
    },
    months: ["Janvāris", "Februāris", 'Marts', 'Aprīlis', 'Maijs', 'Jūnijs', 'Jūlijs', 'Augusts', 'Septembris', 'Oktobris', 'Novembris', 'Decembris'],
    monthsShort: ["Janvāris", "Februāris", 'Marts', 'Aprīlis', 'Maijs', 'Jūnijs', 'Jūlijs', 'Augusts', 'Septembris', 'Oktobris', 'Novembris', 'Decembris'],
});

const { SubMenu } = Menu;

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            en: {
                translation: {
                    "Welcome to React": "Welcome to React and react-i18next"
                }
            },
            lv: {
                translation: lv
            }
        },
        lng: "lv", // if you're using a language detector, do not define the lng option
        fallbackLng: "lv",
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

class App extends React.Component<{}, {
    windowHeight: number,
    login: boolean,
    loaded: boolean,
    permissions: any,
    rights: any,
    self: any,
    menuHistory: any,
}> {
    constructor(props: {}) {
        super(props);

        this.state = {
            windowHeight: window.innerHeight,
            login: false,
            loaded: false,
            permissions: [],
            rights: [],
            self: null as any,
            menuHistory: {
                prev: undefined,
                real: undefined
            }
        };
    }

    handleWindowResize = () => {
        this.setState({ windowHeight: window.innerHeight });
    }

    checkCache = async () => {
        if (Number(process.env.REACT_APP_DEV) === 1) return;
        http.get('build-time?app=admin').then((response: any) => {
            console.log(response.data.build_time, buildJson.buildTime)
            if (!(response.data.build_time === buildJson.buildTime)) {
                window.location.href = window.location.href;
                console.log("Clearing cache")
                window.location.reload();
            }
        })
    }

    setSaltedToken = async () => {
        // await http.get(`rest/get-salted-token`).then((res: AxiosResponse) => {
        //     microApi.defaults.headers.common["Authorization"] = `Bearer ${res.data}`;
        // })
    }

    componentDidMount() {
        this.checkCache();
        window.addEventListener('resize', this.handleWindowResize);
        const sp = new URLSearchParams(window.location.search);
        const savedToken = sp.get("bt");
        if (savedToken != null) {
            localStorage.setItem("bearer_token", savedToken);
            localStorage.setItem("ttl", moment().format("YYYY-MM-DD"));
            window.location.href = "/";
        }

        const token = localStorage.getItem("bearer_token");
        const self = localStorage.getItem('self');
        const ttl = localStorage.getItem('ttl');
        if (ttl) {
            if (!moment(ttl, "YYYY-MM-DD").isSame(moment(), "day")) {
                localStorage.clear();
                window.location.href = "/";
            }
        }
        if (token == null) {
            this.setState({ login: true, loaded: true });
        } else {
            http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            this.setState({
                self: JSON.parse(self ? self : "")
            });
            // this.setSaltedToken().then(() => {
            //     microApi.get(`validate_user`).then(() => {
            //         // this.setState({ loaded: true })
            //     })
            // })
            this.getPermissions(token);
        }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (!window.location.pathname.includes("student-opening")) {
            sessionStorage.removeItem("defaultTab")
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    menuLinks = [
        { to: "/users", title: "Lietotāji", permission: [] },
        { to: "/schools", title: "Skolas", permission: ["schools"] },
        {
            to: "/crm", title: "Darāmie darbi", permission: ["crm_reminders", "crm_efficiency", "crm_books_shipping", "crm_missed_lessons", "crm_user_manager"], submenu: [
                {
                    to: "/crm/reminders", title: "Atgādinājumi", permission: ["crm_reminders"]
                },
                {
                    to: "/crm/applications", title: "Pieteikumi", permission: ["crm_applications_statuses"]
                },
                {
                    to: "/crm/reminders-delegated-crms", title: "Piešķirtie pieteikumi", permission: ["crm_user_manager"]
                },
                {
                    to: "/crm/reminders-permissions", title: "Pieteikumu tiesības", permission: ["crm_user_manager"]
                },
                {
                    to: "/crm/books-shipping", title: "Grāmatas uz omniva", permission: ["crm_books_shipping"]
                },
                {
                    to: "/crm/missed-lessons", title: "Neapmeklētās lekcijas", permission: ["crm_missed_lessons"]
                }
            ]
        },
        { to: "/feedback", title: "Atsauksmes", permission: ["feedback"] },
        {
            to: "/notifications", title: "Paziņojumi", permission: ["notifications"], submenu: [
                {
                    to: "/notifications/whatsapp-notifications", title: "Whatsapp", permission: ["notifications"]
                },
                {
                    to: "/notifications/student-spam-filter", title: "Studentu filtrs", permission: ["notifications"]
                },
                {
                    to: "/notifications/templates", title: "Sagataves", permission: ["notifications"]
                },
                {
                    to: "/notifications", title: "Globālie", permission: ["notifications"]
                }
            ]
        },
        { to: "/students", title: "Studenti", permission: ["all"] },
        {
            to: "/groups", title: "Mācību grupas", permission: ["study_groups"], submenu: [
                { to: "/groups/online-stats", title: "Online statistika", permission: ["study_groups"] },
                { to: "/groups/calendar", title: "Kalendārs", permission: ["study_groups"] },
                { to: "/groups", title: "Mācību grupas", permission: ["study_groups"] }
            ]
        },
        {
            to: "/e-learning", title: "E-apmācība", permission: ["study_groups"], submenu: [
                {
                    to: "/e-learning/student-questions", title: "Studentu jautājumi", permission: ["study_groups"]
                },
                {
                    to: "/e-learning/results", title: "Rezultāti", permission: ["study_groups"]
                },
                {
                    to: "/e-learning/csdd-sync-errors", title: "CSDD kļūdas", permission: ["study_groups"]
                },
                {
                    to: "/e-learning", title: "Moduļi", permission: ["study_groups"]
                }
            ]
        },
        { to: "/driving", title: "Braukšana", permission: ["study_groups"] },
        {
            to: "/groups-medical", title: "PMP", permission: ["medical_groups"], submenu: [
                { to: "/groups-medical/groups", title: "Grupas", permission: ["medical_groups"] },
                { to: "/groups-medical/overview", title: "Pārskats", permission: ["medical_groups"] },
            ]
        },
        { to: "/theory-exams", title: "Eksāmeni", permission: ["theory_exams"] },
        {
            to: "/instructors", title: "Instruktori", permission: ["instructors_list", "instructors_timeslots"], submenu: [
                { to: "/instructors/list", title: "Saraksts", permission: ["instructors_list"] },
                { to: "/instructors/timeslots", title: "Brīvās nodarbības", permission: ["instructors_timeslots"] },
                { to: "/instructors/evaluating-options", title: "Vērtēšana", permission: ["instructors_list"] },
                { to: "/instructors/exchange-cars", title: "Maiņas auto", permission: ["instructors_list"] },
                { to: "/instructors/driven-distances", title: "Nobraukumi", permission: ["instructors_list"] }
            ]
        },
        { to: "/invoices", title: "Maksājumi", permission: ["invoices"] },
        {
            to: "/lecturers", title: "Pasniedzēji", permission: ["lecturers"], submenu: [
                { to: "/lecturers/list", title: "Saraksts", permission: ["lecturers"] },
                { to: "/lecturers/stats", title: "Statistika", permission: ["lecturers"] },
            ]
        },
        {
            to: "/options", title: "Opcijas", permission: ["options_settings", "options_holidays"], submenu: [
                { to: "/options/settings", title: "Opcijas", permission: ["options_settings"] },
                { to: "/options/holidays", title: "Svētku dienas", permission: ["options_holidays"] },
                { to: "/options/translations", title: "Tulkojumi", permission: ["translations"] }
            ]
        },
        { to: "/payments", title: "Aprēķini", permission: ["payments"] }
    ];

    logout = async (e: any) => {
        e.preventDefault();
        localStorage.clear();
        sessionStorage.clear();
        // await microApi.get(`clear_room`).catch(() => {
        //     window.location.href = "/";
        // })
        window.location.href = "/";
    }

    getPermissions = async (token: any) => {
        http.get(`/rest/admin-get-perms/${token}`).then((response: any) => {
            this.setState({
                permissions: {
                    perms: response.data.user_permissions,
                    ruler_of_universe: response.data.ruler_of_universe,
                },
                rights: response.data.permissions.rights,
                loaded: true
            })
        })
    }

    setMenuHistory = (e: any) => {
        // console.log(this.state.menuHistory)
        this.setState({ menuHistory: { prev: this.state.menuHistory.real, real: e.item.node.baseURI } })
    }

    mutateHistory = (path: any) => {
        this.setState({ menuHistory: { prev: path, real: this.state.menuHistory.real } })
    }

    render() {
        const normalLayout = <Layout style={{ minHeight: "100vh" }}>
            <Layout.Sider breakpoint='md' collapsedWidth={0}>
                <div className="logo">Einšteins</div>
                <div style={{ padding: "10px 24px 10px", color: "#FFF", border: "1px solid #FFF", marginRight: 2, marginLeft: 2 }}>
                    <b>{this.state.self?.first_name} {this.state.self?.last_name}</b>
                </div>
                <Route path="/" render={({ location }) => {
                    let menuLinks = [];
                    const menuLinksRecursiveFilter = (menu: any) => {
                        const allowedLinks = menu.map((link: any) => {
                            if (this.state.permissions.ruler_of_universe) return link;
                            const allowed = this.state.permissions.perms.some((perm: any) => {
                                if (link.permission.some((el: any) => el === "all")) return true;
                                return perm === link.permission.find((el: any) => el === perm)
                            })
                            if (allowed) {
                                let subLinks;
                                if (link.hasOwnProperty("submenu")) {
                                    subLinks = menuLinksRecursiveFilter(link.submenu);
                                }
                                return subLinks?.length > 0
                                    ? {
                                        ...link,
                                        submenu: subLinks
                                    }
                                    : link
                            }
                            return undefined;
                        }).filter((item: any) => item !== undefined);
                        return allowedLinks;
                    }
                    menuLinks = menuLinksRecursiveFilter(this.menuLinks);

                    const selectedKeys = menuLinks
                        .map((link: any, index: any) => {
                            if (!link) return;
                            return link.hasOwnProperty("submenu")
                                ? link.submenu?.map((sub: any, idx: any) =>
                                    matchPath(location.pathname, { path: sub.to })
                                        ? `${index}${idx}${101}`//String(index + idx * 3)
                                        : null
                                ).filter((value: any) => typeof value === 'string')[0] :
                                matchPath(location.pathname, { path: link.to })
                                    ? String(index + 1)
                                    : null
                        })
                        .filter((value: any) => typeof value === 'string');
                    return (
                        <Menu
                            style={{ margin: "0 0 auto" }}
                            theme="dark"
                            mode="inline"
                            selectedKeys={selectedKeys as any}
                            onSelect={this.setMenuHistory}
                        >
                            {menuLinks.map((link: any, index: any) => {
                                if (!link) return;
                                return link.hasOwnProperty('submenu')
                                    ? <SubMenu key={index + 1} title={link.title}>
                                        {link.submenu?.map((sub: any, idx: any) => {
                                            return <Menu.Item key={`${index}${idx}${101}`}>
                                                <Link to={sub.to}>{sub.title}</Link>
                                            </Menu.Item>
                                        })}
                                    </SubMenu> :
                                    <Menu.Item key={String(index + 1)}>
                                        <Link to={link.to}>{link.title}</Link>
                                    </Menu.Item>
                            }
                            )}
                            <Menu.Item>
                                <a href="/#logout" onClick={this.logout} >Atslēgties</a>
                            </Menu.Item>
                        </Menu>
                    );
                }}>
                </Route>
                {/* <small style={{ color: "rgb(90, 90, 90)", margin: "auto 15px 0" }}>build: {buildJson.buildTime}</small> */}
            </Layout.Sider>

            <Switch>
                <ProtectedRoute path="/users" permissions={this.state.permissions} accept={[]}>
                    <UsersMain rights={this.state.rights} />
                </ProtectedRoute>
                <ProtectedRoute path="/schools" permissions={this.state.permissions} accept={["schools"]}>
                    <SchoolsPage />
                </ProtectedRoute>
                <ProtectedRoute path="/crm/reminders" permissions={this.state.permissions} accept={["crm_reminders"]}>
                    <Reminders />
                </ProtectedRoute>
                <ProtectedRoute path="/crm/applications" permissions={this.state.permissions} accept={["crm_applications_statuses"]}>
                    <ApplicationsView />
                </ProtectedRoute>
                <ProtectedRoute path="/crm/reminders-delegated-crms" permissions={this.state.permissions} accept={["crm_user_manager"]}>
                    <DelegatedReminders admin={this.state.self} />
                </ProtectedRoute>
                <ProtectedRoute path="/crm/reminders-permissions" permissions={this.state.permissions} accept={["crm_user_manager"]}>
                    <RemindersAccess admin={this.state.self} />
                </ProtectedRoute>
                <ProtectedRoute path="/crm/books-shipping" permissions={this.state.permissions} accept={["crm_books_shipping"]}>
                    <BooksShipping />
                </ProtectedRoute>
                <ProtectedRoute path="/crm/missed-lessons" permissions={this.state.permissions} accept={["crm_missed_lessons"]}>
                    <MissedLessons />
                </ProtectedRoute>
                <ProtectedRoute path="/feedback" permissions={this.state.permissions} accept={["feedback"]}>
                    <FeedbackPage />
                </ProtectedRoute>
                <ProtectedRoute path="/notifications/whatsapp-notifications" permissions={this.state.permissions} accept={["notifications"]}>
                    <WhatsappNotifications />
                </ProtectedRoute>
                <ProtectedRoute path="/notifications/student-spam-filter" permissions={this.state.permissions} accept={["notifications"]}>
                    <StudentSpamFilterLayout />
                </ProtectedRoute>
                <ProtectedRoute path="/notifications/templates" permissions={this.state.permissions} accept={["notifications"]}>
                    <TemplatesLayout />
                </ProtectedRoute>
                <ProtectedRoute path="/notifications" permissions={this.state.permissions} accept={["notifications"]}>
                    <NotificationsView />
                </ProtectedRoute>
                <Route path="/students/students-credits" permissions={this.state.permissions} accept={["students"]}>
                    <StudentsCredits />
                </Route>
                <Route path="/students/students-with-remaining-balance" permissions={this.state.permissions} accept={["students"]}>
                    <StudentsRemainingBalance />
                </Route>
                <Route path="/students/edit/:id" permissions={this.state.permissions} accept={["students"]}>
                    <EditStudent permissions={this.state.permissions} />
                </Route>
                {/* <Route path="/students/edit/:id" permissions={this.state.permissions} accept={["students"]}>
                    <EditStudentPage permissions={this.state.permissions} />
                </Route> */}
                <Route path="/students/payments/:id" permissions={this.state.permissions} accept={["students"]}>
                    <StudentPaymentsPage />
                </Route>
                <Route path="/students/from-web" permissions={this.state.permissions} accept={["students"]}>
                    <StudentsFromWeb />
                </Route>
                <Route path="/students/from-web-to-instructors" permissions={this.state.permissions} accept={["students"]}>
                    <StudentsFromWebToInstructors />
                </Route>
                <Route path="/students/students-on-hold" permissions={this.state.permissions} accept={["students"]}>
                    <StudentsOnHold />
                </Route>
                <Route path="/students/new" permissions={this.state.permissions} accept={["students"]}>
                    <NewStudentPage />
                </Route>
                <Route path="/students" permissions={this.state.permissions} accept={["students"]}>
                    <StudentsPage prevLocation={this.state.menuHistory.prev} mutateHistory={this.mutateHistory} windowHeight={this.state.windowHeight} />
                </Route>
                <ProtectedRoute path="/groups/edit/:id/edit" permissions={this.state.permissions} accept={["study_groups_edit"]}>
                    <EditGroupInfo />
                </ProtectedRoute>
                <ProtectedRoute path="/groups/edit/:id/edit-lessons" permissions={this.state.permissions} accept={["study_groups"]}>
                    <EditLessonsSchedule />
                </ProtectedRoute>
                <ProtectedRoute path="/groups/edit/edit-student/:id/:study_group_id/change-group" permissions={this.state.permissions} accept={["study_groups"]}>
                    <GroupsPage prevLocation={this.state.menuHistory.prev} mutateHistory={this.mutateHistory} windowHeight={this.state.windowHeight} rType="change" />
                </ProtectedRoute>
                <ProtectedRoute path="/groups/student-opening/:id" permissions={this.state.permissions} accept={["study_groups"]}>
                    <StudentGroupOpening permissions={this.state.permissions} />
                </ProtectedRoute>
                <ProtectedRoute path="/groups/edit/:id/:reservation/add-student" permissions={this.state.permissions} accept={["study_groups"]}>
                    <AddStudent windowHeight={this.state.windowHeight} />
                </ProtectedRoute>
                <ProtectedRoute path="/groups/edit/:id" permissions={this.state.permissions} accept={["study_groups"]}>
                    <GroupInfoPage permissions={this.state.permissions} accept={['study_groups_edit']} />
                </ProtectedRoute>
                {/* <ProtectedRoute path="/groups/add-instructor" permissions={this.state.permissions} accept={["study_groups"]}>
                    <AddInstructor />
                </ProtectedRoute> */}
                <ProtectedRoute path="/groups/statistics" permissions={this.state.permissions} accept={["study_groups"]}>
                    <GrouptStatisticsLayout />
                </ProtectedRoute>
                <ProtectedRoute path="/groups/online-stats/:id/:cid/:lid/:mid" permissions={this.state.permissions} accept={["study_groups"]}>
                    <ModuleDetails />
                </ProtectedRoute>
                <ProtectedRoute path="/groups/online-stats/:id/:cid/:lid" permissions={this.state.permissions} accept={["study_groups"]}>
                    <CityDetails />
                </ProtectedRoute>
                <ProtectedRoute path="/groups/online-stats" permissions={this.state.permissions} accept={["study_groups"]}>
                    <OnlineStatsLayout />
                </ProtectedRoute>
                <ProtectedRoute path="/groups/new" permissions={this.state.permissions} accept={["study_groups"]}>
                    <NewGroup />
                </ProtectedRoute>
                <ProtectedRoute path="/groups/edit-comments" permissions={this.state.permissions} accept={["study_groups"]}>
                    <MassGroupsEdit />
                </ProtectedRoute>
                <ProtectedRoute path="/groups/templates/new" permissions={this.state.permissions} accept={["study_groups"]}>
                    <GroupTemplate />
                </ProtectedRoute>
                <ProtectedRoute path="/groups/templates/:id" permissions={this.state.permissions} accept={["study_groups"]}>
                    <GroupTemplate />
                </ProtectedRoute>
                <ProtectedRoute path="/groups/templates" permissions={this.state.permissions} accept={["study_groups"]}>
                    <GroupTemplatesPage />
                </ProtectedRoute>
                <ProtectedRoute path="/groups/calendar" permissions={this.state.permissions} accept={["study_groups"]}>
                    <GroupsCalendarLayout />
                </ProtectedRoute>
                <ProtectedRoute path="/groups" permissions={this.state.permissions} accept={["study_groups"]}>
                    <GroupsPage prevLocation={this.state.menuHistory.prev} mutateHistory={this.mutateHistory} windowHeight={this.state.windowHeight} rType="list" />
                </ProtectedRoute>
                {/* <ProtectedRoute path="/e-learning/results/:id" permissions={this.state.permissions} accept={["study_groups"]}>
                    <ExamResults />
                </ProtectedRoute> */}
                <ProtectedRoute path="/e-learning/student-questions" permissions={this.state.permissions} accept={["study_groups"]}>
                    <StudentQuestionsView />
                </ProtectedRoute>
                <ProtectedRoute path="/e-learning/results" permissions={this.state.permissions} accept={["study_groups"]}>
                    <ResultsPage />
                </ProtectedRoute>
                <ProtectedRoute path="/e-learning/:id/edit-module/:mid/edit-chapter/:cid" permissions={this.state.permissions} accept={["study_groups"]}>
                    <ChapterView />
                </ProtectedRoute>
                <ProtectedRoute path="/e-learning/:id/edit-module/:mid/exam-questions" permissions={this.state.permissions} accept={["study_groups"]}>
                    <ExamQuestionsView />
                </ProtectedRoute>
                <ProtectedRoute path="/e-learning/:id/edit-module/:mid" permissions={this.state.permissions} accept={["study_groups"]}>
                    <ModuleView />
                </ProtectedRoute>
                <ProtectedRoute path="/e-learning/csdd-sync-errors" permissions={this.state.permissions} accept={["study_groups"]}>
                    <SyncErrorsPage />
                </ProtectedRoute>
                <ProtectedRoute path="/e-learning/:id" permissions={this.state.permissions} accept={["study_groups"]}>
                    <CategoryView />
                </ProtectedRoute>
                <ProtectedRoute path="/e-learning" permissions={this.state.permissions} accept={["study_groups"]}>
                    <ElearningMain />
                </ProtectedRoute>
                <ProtectedRoute path="/driving" permissions={this.state.permissions} accept={["study_groups"]}>
                    <DrivingSync />
                </ProtectedRoute>
                <ProtectedRoute path="/groups-medical/groups/new" permissions={this.state.permissions} accept={["medical_groups"]}>
                    <NewMedicalGroup />
                </ProtectedRoute>
                <ProtectedRoute path="/groups-medical/groups/:id" permissions={this.state.permissions} accept={["medical_groups"]}>
                    <EditMedicalGroup />
                </ProtectedRoute>
                <ProtectedRoute path="/groups-medical/overview" permissions={this.state.permissions} accept={["medical_groups"]}>
                    <PMPOverview />
                </ProtectedRoute>
                <ProtectedRoute path="/groups-medical/groups" permissions={this.state.permissions} accept={["medical_groups"]}>
                    <MedicalGroups />
                </ProtectedRoute>
                <ProtectedRoute path="/theory-exams" permissions={this.state.permissions} accept={["theory_exams"]}>
                    <TheoryExams />
                </ProtectedRoute>
                <ProtectedRoute path="/instructors/edit/:id" permissions={this.state.permissions} accept={["instructors_list"]}>
                    <EditInstructorPage />
                </ProtectedRoute>
                <ProtectedRoute path="/instructors/new" permissions={this.state.permissions} accept={["instructors_list"]}>
                    <NewInstructorPage />
                </ProtectedRoute>
                <ProtectedRoute path="/instructors/timeslots" permissions={this.state.permissions} accept={["instructors_timeslots"]}>
                    <FreeSlotsPage />
                </ProtectedRoute>
                <ProtectedRoute path="/instructors/evaluating-options" permissions={this.state.permissions} accept={["instructors_list"]}>
                    <EvaluatingOptionsLayout />
                </ProtectedRoute>
                <ProtectedRoute path="/instructors/exchange-cars" permissions={this.state.permissions} accept={["instructors_list"]}>
                    <ExchangeCarsLayout />
                </ProtectedRoute>
                <ProtectedRoute path="/instructors/driven-distances" permissions={this.state.permissions} accept={["instructors_list"]}>
                    <DrivenStats />
                </ProtectedRoute>
                <ProtectedRoute path="/instructors/list" permissions={this.state.permissions} accept={["instructors_list"]}>
                    <InstructorsPage prevLocation={this.state.menuHistory.prev} mutateHistory={this.mutateHistory} windowHeight={this.state.windowHeight} />
                </ProtectedRoute>
                <ProtectedRoute path="/invoices/instructor/:id" permissions={this.state.permissions} accept={["invoices"]}>
                    <InstructorInvoice />
                </ProtectedRoute>
                <ProtectedRoute path="/invoices/instructors-balance/:id" permissions={this.state.permissions} accept={["invoices"]}>
                    <InstructorBalance />
                </ProtectedRoute>
                <ProtectedRoute path="/invoices/invoice-details/:id" permissions={this.state.permissions} accept={["invoices"]}>
                    <InvoiceDetails />
                </ProtectedRoute>
                <ProtectedRoute path="/invoices" permissions={this.state.permissions} accept={["invoices"]}>
                    <InvoicesList />
                </ProtectedRoute>
                <ProtectedRoute path="/lecturers/list/edit/:id" permissions={this.state.permissions} accept={["lecturers"]}>
                    <EditLecturerPage />
                </ProtectedRoute>
                <ProtectedRoute path="/lecturers/list/new" permissions={this.state.permissions} accept={["lecturers"]}>
                    <NewLecturerPage />
                </ProtectedRoute>
                <ProtectedRoute path="/lecturers/list" permissions={this.state.permissions} accept={["lecturers"]}>
                    <LecturerPage windowHeight={this.state.windowHeight} />
                </ProtectedRoute>
                <ProtectedRoute path="/lecturers/stats" permissions={this.state.permissions} accept={["lecturers"]}>
                    <LecturersStats />
                </ProtectedRoute>
                <ProtectedRoute path="/options/settings/search-exceptions" permissions={this.state.permissions} accept={["options_settings"]}>
                    <ExceptionsForInstructorsSearch />
                </ProtectedRoute>
                <ProtectedRoute path="/options/settings" permissions={this.state.permissions} accept={["options_settings"]}>
                    <OptionsPage />
                </ProtectedRoute>
                <ProtectedRoute path="/options/holidays" permissions={this.state.permissions} accept={["options_holidays"]}>
                    <HolidaysPage />
                </ProtectedRoute>
                <ProtectedRoute path="/options/translations" permissions={this.state.permissions} accept={["translations"]}>
                    <TranslationsView />
                </ProtectedRoute>
                <ProtectedRoute path="/payments/:id" permissions={this.state.permissions} accept={["payments"]}>
                    <PaymentsPage />
                </ProtectedRoute>
                <ProtectedRoute path="/payments" permissions={this.state.permissions} accept={["payments"]}>
                    <PaymentsPage />
                </ProtectedRoute>
                <Route exact path="/">
                    <Redirect to="/students" />
                </Route>
            </Switch>
        </Layout>;

        const onLogin = async ({ email, password }: any) => {
            try {
                const result = await http.post("login", { email, password });
                const bearer_token = result.data.data.api_token;
                const self = result.data.data;
                if (bearer_token != null) {
                    localStorage.setItem("bearer_token", bearer_token);
                    localStorage.setItem("self", JSON.stringify(self));
                    localStorage.setItem("ttl", moment().format("YYYY-MM-DD"));
                    window.location.href = "/";
                } else {
                    throw new Error();
                }
            } catch (e) {
                console.error(e?.response?.data?.message);
                if (e?.response?.data?.message === "Bad credentials.") {
                    message.error("Nepareizs lietotājvārds vai parole")
                } else {
                    message.error(`Servera kļūda ${e?.response?.status ?? ""}`)
                }

            }
        };

        const loginLayout = (
            <Layout style={{ height: "100vh", background: "#222", backgroundPosition: "50% bottom" }}>
                <Card title="Pieslēgties administratora portālam" style={{ width: 400, margin: "20vh auto" }}>
                    <Form name="login_form" onFinish={onLogin} layout="vertical">
                        <Form.Item name="email" label="Lietotājs">
                            <Input />
                        </Form.Item>
                        <Form.Item name="password" label="Parole">
                            <Input type="password" />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary">Ienākt</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Layout>
        );

        let out = null as any;
        if (this.state.loaded) {
            out = this.state.login ? loginLayout : normalLayout;
        }

        return <Router>
            <div className="App">
                {out}
            </div>
        </Router>
    };
}

export default App;
