import { Button, Card, Collapse, Form, Input, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import lv from "../../locale/student/lv.json"
import en from "../../locale/student/en.json"
import ru from "../../locale/student/ru.json"
import { http } from "../../helpers/http";

export const TranslationsForm = (props: any) => {
    const [translations, setTranslations] = useState({} as any);
    const [searchCriteria, setSearchCriteria] = useState("");
    const [formObj, setFormObj] = useState({} as any);

    const headingsTranslations = {
        common: "Pamata",
        weekdays: "Nedēļas dienas",
        groups: "Grupas",
        students: "Studenti",
        users: "Lietotāji",
        errors: "Kļūdas",
        balance: "Bilance",
        driving: "Braukšanas nodarbības",
        login: "Piekļuve",
        payments: "Maksājumi",
        calendar: "Plānotājs",
        instructor_card: "Instruktora kartiņa",
        theory: "Teorija",
        notification: "Paziņojumi",
        navigation: "Navigācija",
        e_learning: "E-apmācība",
        transmission: "Pārnesumkārba",
        warning: "Brīdinājumi",
        top_info_card: "Braukšanas info kartiņa",
        new_password: "Jauna parole",
        agreement: "Līgumi",
        info: "Informācija",
        requisites: "Rekvizīti",
        Payment_method: "Maksāšanas veidi",
        additional_info: "Papildus apraksts",
        cancel_driving_lesson: "Braukšanas nodarbības atcelšana",
        student_cancel_48h: "Students atceļ 48st pirms nodarbības",
        student_can_go_driving_with_instructor: "Students var braukt (ar instruktoru)",
        student_can_go_driving_without_instructor: "Students var braukt (bez instruktora)",
        student_password_reset_api: "Studenta paroles maiņa",
        student_password_reset: "Studenta paroles dzēšana",
        student_notifications: "Studenta paziņojumi",
        checkpoints: "Checkpoints",
        theory_exams: "Teorijas eksāmeni",
        crm: "CRM",
        actions: "Notikumi",
        admin_notifications: "Admina paziņojumi",
        cancel_theory_exam: "Teorijas eksāmena paziņojumi",
        channels: "Kanāli",
        tutorial: "Pamācība",
        step_one: "Solis 1",
        step_two: "Solis 2",
        step_three: "Solis 3",
        step_four: "Solis 4",
        step_five: "Solis 5",
        step_six: "Solis 6",
        step_seven: "Solis 7",
        step_eight: "Solis 8",
        final_step: "Pēdējais solis",
        eval: "Vērtēšana",
        dashboard: "Kopsavilkums",
        filters: "Filtri",
        settings: "Uzstādījumi",
        school_notifications: "Skolu paziņojumi",
        admin_password_reset_api: "eDrive Lietotāju paroles maiņa",
        admin_password_reset: "eDrive Lietotāju paroles dzēšana",
        completed: "Pabeigta",
        school_student_password_reset_api: "eDrive studenta paroles maiņa",
        school_student_password_reset: "eDrive studenta paroles dzēšana",
        bonus: "Bonusi",
        app: "Mobilā lietotne",
    } as any;

    const regex = /(\{|\[|\<)(.*?)(\}|\]|\>)/g;

    const fetchTranslations = async () => {
        // microApi.get(`translations`).then((response: any) => {
        //     const { data } = response.data;
        //     const lv = JSON.parse(data[0].translation);
        //     const en = JSON.parse(data[2].translation);
        //     const ru = JSON.parse(data[1].translation);
        //     setTranslations(() => {
        //         return {
        //             lv,
        //             en,
        //             ru
        //         }
        //     })
        //     setFormObj(JSON.parse(data[1].translation))
        // })
        http.get(`/rest/translations`).then((response: any) => {
            const { data } = response.data;
            setTranslations(() => {
                return {
                    lv: { ...data[1].translation },
                    en: { ...data[3].translation },
                    ru: { ...data[2].translation }
                }
            })
            setFormObj(data[1].translation)
        })
    };

    const updateFromFile = () => {
        http.post(`/rest/translations`, { language_id: 1, translation: { ...lv } }).then((response: any) => {
            message.success("Latviešu tulkojumi saglabāti");
        })
        http.post(`/rest/translations`, { language_id: 2, translation: { ...ru } }).then((response: any) => {
            message.success("Krievu tulkojumi saglabāti");
        })
        http.post(`/rest/translations`, { language_id: 3, translation: { ...en } }).then((response: any) => {
            message.success("Angļu tulkojumi saglabāti");
        })
    };

    const searchKeyWord = (keyWord: string) => {
        if (keyWord.length === 0) {
            setFormObj(translations.lv);
            return;
        }
        const objToSearch = translations.lv;
        let newObj: any;
        Object.keys(objToSearch).forEach((key: string) => {
            Object.keys(objToSearch[key]).map((key2: string) => {
                if (typeof objToSearch[key][key2] === "object" && objToSearch[key][key2] !== null) {
                    Object.keys(objToSearch[key][key2]).map((key3: string) => {
                        if (typeof objToSearch[key][key2][key3] === "object" && objToSearch[key][key2][key3] !== null) {
                            Object.keys(objToSearch[key][key2][key3]).map((key4: string) => {
                                if (!objToSearch[key][key2][key3][key4]) return;
                                if (objToSearch[key][key2][key3][key4]?.toLowerCase().includes(keyWord.toLowerCase())) {
                                    // console.log(objToSearch[key][key2][key3][key4], keyWord)
                                    newObj = {
                                        ...newObj,
                                        [key]: {
                                            [key2]: {
                                                [key3]: {
                                                    [key4]: objToSearch[key][key2][key3][key4]
                                                }
                                            }
                                        }
                                    }
                                }
                            })
                        } else {
                            if (!objToSearch[key][key2][key3]) return;
                            if (objToSearch[key][key2][key3]?.toLowerCase().includes(keyWord.toLowerCase())) {
                                // console.log(objToSearch[key][key2][key3], keyWord)
                                newObj = {
                                    ...newObj,
                                    [key]: {
                                        [key2]: {
                                            [key3]: objToSearch[key][key2][key3]
                                        }
                                    }
                                }
                            }
                        }
                    })
                } else {
                    if (!objToSearch[key][key2]) return;

                    // console.log(objToSearch, keyWord)
                    if (objToSearch[key][key2]?.toLowerCase().includes(keyWord.toLowerCase())) {
                        // console.log(objToSearch[key][key2], keyWord)
                        newObj = {
                            ...newObj,
                            [key]: {
                                [key2]: objToSearch[key][key2]
                            }
                        }
                    }
                }
            })
        })
        setFormObj(newObj)
    }

    const saveForm = () => {
        http.post(`/rest/translations`, { language_id: 1, translation: { ...translations.lv } }).then((response: any) => {
            message.success("Latviešu tulkojumi saglabāti");
        })
        http.post(`/rest/translations`, { language_id: 2, translation: { ...translations.ru } }).then((response: any) => {
            message.success("Krievu tulkojumi saglabāti");
        })
        http.post(`/rest/translations`, { language_id: 3, translation: { ...translations.en } }).then((response: any) => {
            message.success("Angļu tulkojumi saglabāti");
        })
    }

    useEffect(() => {
        fetchTranslations();
    }, []);

    return <Form
        layout="vertical"
        initialValues={translations.lv}
    >
        {
            process.env.REACT_APP_DEV === "1"
                ? <button
                    onClick={() => {
                        updateFromFile();
                    }}
                >
                    Update from JSON (WARNING)
                </button>
                : <></>
        }
        <Row>
            <Input
                style={{ width: 300, marginBottom: 20 }}
                placeholder="Meklēt..."
                value={searchCriteria}
                onChange={(e) => {
                    setSearchCriteria(e.target.value)
                }}
            />
            <Button
                onClick={() => searchKeyWord(searchCriteria)}
            >
                Meklēt
            </Button>
            <Button
                onClick={
                    () => {
                        searchKeyWord("");
                        setSearchCriteria("")
                    }
                }
            >
                Dzēst filtru
            </Button>
        </Row>
        {
            (formObj
                && Object.keys(formObj).length > 0)
            && <>
                {
                    Object.keys(formObj).map((key: string, index: number) => {
                        return <Collapse key={key + index}>
                            <Collapse.Panel header={headingsTranslations[key]} key={key}>
                                {
                                    Object.keys(formObj[key]).map((key2: string, index2: number) => {
                                        if (typeof formObj[key][key2] === "object" && formObj[key][key2] !== null) {
                                            return <Collapse key={key + index + index2}>
                                                <Collapse.Panel header={headingsTranslations[key2]} key={key2}>
                                                    {
                                                        Object.keys(formObj[key][key2]).map((key3: string, index3: number) => {
                                                            return <Row key={key3 + index + index2 + index3}>
                                                                <Form.Item
                                                                    style={{ margin: "0 30px 20px 0", width: 300 }}
                                                                    label="Latviešu"
                                                                >
                                                                    <Input.TextArea
                                                                        placeholder={key3}
                                                                        value={translations.lv[key][key2][key3]}
                                                                        onChange={(e) => {
                                                                            e.persist();
                                                                            setTranslations((prev: any) => {
                                                                                if (prev.lv[key][key2][key3].match(regex) && !e.target.value.match(regex)) return { ...prev }
                                                                                if (prev.lv[key][key2][key3].match(regex) && e.target.value.match(regex)) {
                                                                                    if (JSON.stringify(prev.lv[key][key2][key3].match(regex)) !== JSON.stringify(e.target.value.match(regex))) return { ...prev }
                                                                                }
                                                                                const copy = JSON.parse(JSON.stringify(prev));
                                                                                copy.lv[key][key2][key3] = e.target.value;
                                                                                return copy;
                                                                            })
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    style={{ margin: "0 30px 20px 0", width: 300 }}
                                                                    label="Angļu"
                                                                >
                                                                    <Input.TextArea
                                                                        placeholder={key3}
                                                                        value={translations.en[key][key2][key3]}
                                                                        onChange={(e) => {
                                                                            e.persist();
                                                                            setTranslations((prev: any) => {
                                                                                const copy = JSON.parse(JSON.stringify(prev));
                                                                                copy.en[key][key2][key3] = e.target.value;
                                                                                return copy;
                                                                            })
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    style={{ margin: "0 30px 20px 0", width: 300 }}
                                                                    label="Krievu"
                                                                >
                                                                    <Input.TextArea
                                                                        placeholder={key3}
                                                                        value={translations.ru[key][key2][key3]}
                                                                        onChange={(e) => {
                                                                            e.persist();
                                                                            setTranslations((prev: any) => {
                                                                                const copy = JSON.parse(JSON.stringify(prev));
                                                                                copy.ru[key][key2][key3] = e.target.value;
                                                                                return copy;
                                                                            })
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                            </Row>
                                                        })
                                                    }
                                                </Collapse.Panel>
                                            </Collapse>
                                        }
                                        return <Row key={key2 + index + index2}>
                                            <Form.Item
                                                style={{ margin: "0 30px 20px 0", width: 300 }}
                                                label="Latviešu"
                                            >
                                                <Input.TextArea
                                                    placeholder={key2}
                                                    value={translations.lv[key][key2]}
                                                    onChange={(e) => {
                                                        e.persist();
                                                        setTranslations((prev: any) => {
                                                            console.log(e.target.value.match(regex))
                                                            const copy = JSON.parse(JSON.stringify(prev));
                                                            copy.lv[key][key2] = e.target.value;
                                                            return copy;
                                                        })
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                style={{ margin: "0 30px 20px 0", width: 300 }}
                                                label="Angļu"
                                            >
                                                <Input.TextArea
                                                    placeholder={key2}
                                                    value={translations.en[key][key2]}
                                                    onChange={(e) => {
                                                        e.persist();
                                                        setTranslations((prev: any) => {
                                                            const copy = JSON.parse(JSON.stringify(prev));
                                                            copy.en[key][key2] = e.target.value;
                                                            return copy;
                                                        })
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                style={{ margin: "0 30px 20px 0", width: 300 }}
                                                label="Krievu"
                                            >
                                                <Input.TextArea
                                                    placeholder={key2}
                                                    value={translations.ru[key][key2]}
                                                    onChange={(e) => {
                                                        e.persist();
                                                        setTranslations((prev: any) => {
                                                            const copy = JSON.parse(JSON.stringify(prev));
                                                            copy.ru[key][key2] = e.target.value;
                                                            return copy;
                                                        })
                                                    }}
                                                />
                                            </Form.Item>
                                        </Row>
                                    })
                                }
                            </Collapse.Panel>
                        </Collapse>
                    })
                }
                <Row justify="end">
                    <Button
                        type="primary"
                        onClick={saveForm}
                    >
                        Saglabāt
                    </Button>
                </Row>
            </>
        }
    </Form>
}